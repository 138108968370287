<template>
  <HelpBox
    :question="question"
    :answer="answer"
    :link="link"
    :link-title="linkTitle"
  />
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      question() {
        return this.getValue("question")
      },
      answer() {
        return this.getValue("answer")
      },
      link() {
        return this.getIngredient("link")?.linkUrl
      },
      linkTitle() {
        return this.getValue("link")
      },
    },
  }
</script>
