<template>
  <Container>
    <div class="helpbox">
      <div class="helpbox-content">
        <b>
          {{ question }}
        </b>
        {{ answer }}
      </div>
      <Button v-if="link" :to="link" size="small" color="blue">
        {{ linkTitle }}
      </Button>
    </div>
  </Container>
</template>

<script>
  import Container from "~/components/Container"
  import Button from "~/components/Button"

  export default {
    components: { Container, Button },
    props: {
      question: {
        type: String,
        default: "",
      },
      answer: {
        type: String,
        default: "",
      },
      link: {
        type: String,
        default: "",
      },
      linkTitle: {
        type: String,
        default: "",
      },
    },
  }
</script>

<style lang="scss" scoped>
  .helpbox {
    border-radius: $border-radius;
    color: $blue-text;
    background: $blue-tint;
    padding: $base-spacing * 4 $base-spacing * 8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include viewport("mini", "sm") {
      padding: $base-spacing * 4 $base-spacing * 6;
    }
    @include viewport("mini") {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .helpbox-content {
    @include viewport("mini", "sm") {
      display: flex;
      flex-direction: column;
    }
    @include viewport("mini") {
      margin-bottom: $space-s;
    }
  }
</style>
